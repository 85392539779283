<div class="row">
    <div class="col-4">
        <img width="70%" src="../../../assets/img/LOGO-3_resize_200_133.png">
    </div>
    <div class="col-4">

    </div>
    <div class="col-4 align-self-center">
        <span class="nav_text_right">{{adaptiv_slogan_1}}</span>
    </div>
</div>
<br />
<div class="row">
    <div class="col-12">
        <div class="card bg-c-blue order-card shadow">
            <img class="card-img card-img-cover" src="../../../assets/img/laptop_table2.jpg" alt="Card image">
            <div class="card-img-overlay">

            </div>

        </div>
        <br />
        <div class="row">
            <div class="col">
                <hr>
            </div>
            <div class="col-auto text-divider">{{adaptiv_slogan_2}}</div>
            <div class="col">
                <hr>
            </div>
        </div>
        <br />
        <div class="container">
            <div class="row">
                <div class="col-4">
                    <div class="card card_custom">
                        
                        <div class="card-body">
                            <div class="row">
                                <div class="col-12 nav_text_section">
                                    <a (click)="openServices($event)" href="">{{BUTTON_WEB_DEVELOPMENT}}</a>
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <div class="col-12 nav_text_section">
                                    <a (click)="openDocman($event)" href="">{{BUTTON_DOCUMENT_MANAGEMENT}}</a>
                                </div>
                            </div>
                            <br />
                            <div class="row">
                                <div class="col-12 nav_text_section">
                                    <a (click)="openDocBpm($event)" href="">{{BUTTON_BPM}}</a>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                <div class="col-8">
                    <div class="card card_custom">
                        <div class="card-header text-center bg-c-blue order-card">What We Do</div>
                        <div class="card-body">
                            <ul id="list"> 
                                <li> {{adaptiv_desc_1}}</li> 
                                <li> {{adaptiv_desc_2}}</li> 
                                <li> {{adaptiv_desc_3}}</li> 
                                <li> {{adaptiv_desc_4}}</li> 
                            </ul>
                        
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>




    </div>
 
</div>