<form>
    <div class="modal-header">
        <h4 class="modal-title">
            {{modal_header_title}}
        </h4>
    </div>
    <div class="modal-body">
        <p>
            {{modal_body}}
        </p>
    </div>
    <div class="modal-footer">
        <button class="btn btn-outline-secondary" [hidden]="enableHome" (click)="goHome()">Okay</button>
    </div>
</form>