import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/model/constants/app-config';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  adaptiv_desc_1 = AppConfig.ADAPTIVE_DESCRIPTION_1;
  adaptiv_desc_2 = AppConfig.ADAPTIVE_DESCRIPTION_2;
  adaptiv_desc_3 = AppConfig.ADAPTIVE_DESCRIPTION_3;
  adaptiv_desc_4 = AppConfig.ADAPTIVE_DESCRIPTION_4;
  adaptiv_slogan_1 = AppConfig.ADAPTIVE_SLOGAN_1;
  adaptiv_slogan_2 = AppConfig.ADAPTIVE_SLOGAN_2;

  BUTTON_WEB_DEVELOPMENT = AppConfig.BUTTON_WEB_DEVELOPMENT;
  BUTTON_DOCUMENT_MANAGEMENT = AppConfig.BUTTON_DOCUMENT_MANAGEMENT;
  BUTTON_BPM = AppConfig.BUTTON_BPM;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  openServices(action){
    this.router.navigate([AppConfig.ROUTE_URL_SERVICES], { queryParams: {type: AppConfig.QUERY_TYPE_WEBDEV}});
    action.preventDefault();
  }


  openDocman(action){
    this.router.navigate([AppConfig.ROUTE_URL_SERVICES], { queryParams: {type: AppConfig.QUERY_TYPE_DOCMAN}});
    action.preventDefault();
  }

  openDocBpm(action){
    this.router.navigate([AppConfig.ROUTE_URL_SERVICES], { queryParams: {type: AppConfig.QUERY_TYPE_BPM}});
    action.preventDefault();
  }

}
