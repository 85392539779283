<form [formGroup]="UserContactGroup">
    <div class="container">
        <div class="row">
            <div class="col-4">
                <img width="75%" src="../../../assets/img/LOGO-3_resize_200_133.png">
            </div>
            <div class="col-4">

            </div>
            <div class="col-4 align-self-center">

            </div>
        </div>
    </div>
    <br />


    <div class="container">
   
        <div class="row">
            <div class="col"><hr></div>
            <div class="col-auto text-divider">Contact Information</div>
            <div class="col"><hr></div>
        </div>
        <br />
        <div class="row">
            <div class="col-3"></div>
            <div class="col-4">
                <div class="form-group">
                    Adaptiv Information Systems LLC <br />
                    2764 Chalford Circle NW<br />
                    North Canton, OH 44720
                </div>
            </div>
            <div class="col-2">
                <div class="form-group">
                    support@adaptiv-is.com <br />
                    Phone: (571) 639-1902
                </div>
            </div>
        </div>
    </div>


    <div class="container">
        
            <div class="row">
                <div class="col-3"></div>
                <div class="col-6">
                    <div class="card">
                        <div class="card-header bg-c-blue order-card">Request For Information</div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input maxlength="50" required formControlName="UserContactName"
                                            class="form-control" type="text">
                                        <span
                                            *ngIf="registerFormControl.UserContactName.invalid && (registerFormControl.UserContactName.dirty || registerFormControl.UserContactName.touched)"
                                            class="text-danger">
                                            Contact Name is Required.
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input maxlength="50" required formControlName="UserContactPhone"
                                        mask="(000) 000-0000" placeholder="(000) 000-0000"  class="form-control" type="text">
                                        <span
                                            *ngIf="registerFormControl.UserContactPhone.invalid && (registerFormControl.UserContactPhone.dirty || registerFormControl.UserContactPhone.touched)"
                                            class="text-danger">
                                            Contact Phone Required.
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div class="row">

                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input maxlength="50" required formControlName="UserContactEmail"
                                            class="form-control" type="text">
                                        <span
                                            *ngIf="registerFormControl.UserContactEmail.invalid && (registerFormControl.UserContactEmail.dirty || registerFormControl.UserContactEmail.touched)"
                                            class="text-danger">
                                            Contact Email is Required.
                                        </span>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <label>Request Summary</label>
                                        <textarea formControlName="UserContactMessage" rows="5" cols="60"></textarea>
                                        <span
                                            *ngIf="registerFormControl.UserContactMessage.invalid && (registerFormControl.UserContactMessage.dirty || registerFormControl.UserContactMessage.touched)"
                                            class="text-danger">
                                            Contact Message is Required.
                                        </span>
                                    </div>
                                </div>

                            </div>
                            <div class="row">
                                <div class="col-6">
                                    <div class="form-group">
                                        <input (click)="sendRequest()" class="btn btn-md btn-primary btn-block"
                                            value="Submit" type="button">
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="col-3"></div>
               
            </div>
        
    </div>
</form>