import { Injectable } from '@angular/core';
import { AppConfig } from '../model/constants/app-config';
import { AngularFirestore } from '@angular/fire/firestore';
import emailjs, { EmailJSResponseStatus } from 'emailjs-com';

@Injectable({
  providedIn: 'root'
})
export class AdaptivedataApiService {

  constructor(private dataService: AngularFirestore) { }


  /**
   * Method persists User object back to database
   * @param customer User Object
   */
   saveProfileDb(customer: any) {
    return new Promise<any>((resolve, reject) => {

      this.dataService.collection(AppConfig.DATABASE_TABLE_CUSTOMER)
        .add(customer)
        .then((res) => {
          resolve(res);
        }).catch((err) =>
        {
          console.log(err);
          reject(err);
        });
        
    })
  }

  /**
   * Method to send email through EmailJS
   * Account Management URL: https://www.emailjs.com/docs/examples/angular/
   * Username: support@lajeansmhb.org
   * @param emailAddress 
   */
   sendEmail(emailAddress: string, firstname: string) {
    emailjs.send(
      AppConfig.EMAILJS_SERVICEID, 
      AppConfig.EMAILJS_TEMPLATEID, 
      {
        firstname: firstname,
        to_email_address: emailAddress
      },
      AppConfig.EMAILJS_USERID
    ).then((result: EmailJSResponseStatus) => { 
      console.log(result.text);
    }, (error) => {
      console.log(error.text);
    });
  }

}
