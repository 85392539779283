import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppConfig } from 'src/app/model/constants/app-config';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {

  isWebDevelopment: boolean = false;
  isDocumentManagement: boolean = false;
  isBpm: boolean = false;
  showSlogan = false;
  adaptive_slogan3 = AppConfig.ADAPTIVE_SLOGAN_3;
  constructor(private _activatedRoute: ActivatedRoute,
              private _router: Router) 
  {
    _router.routerState.root.queryParams.subscribe(
      params => {
        console.log('queryParams', params[AppConfig.QUERY_TYPE])
        if(params[AppConfig.QUERY_TYPE] == AppConfig.QUERY_TYPE_DOCMAN)
        {
          this.isDocumentManagement = true;
          this.showSlogan = true;
        }
        else if(params[AppConfig.QUERY_TYPE] == AppConfig.QUERY_TYPE_WEBDEV){
          this.isWebDevelopment = true;
          this.showSlogan = true;
        }
        else if(params[AppConfig.QUERY_TYPE] == AppConfig.QUERY_TYPE_BPM){
          this.isBpm = true;
          this.showSlogan = true;
        }
        else
        {
          this._router.navigate([AppConfig.ROUTE_URL_HOME]);
        }
      }
    );
  }


  ngOnInit(): void {
  }

}
