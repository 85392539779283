import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { RouteConfigLoadEnd, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { SubmissionStatusModalComponent } from 'src/app/modals/submission-status-modal/submission-status-modal.component';
import { AppConfig } from 'src/app/model/constants/app-config';

import { Contact, Customer } from 'src/app/model/contact.model';
import { AdaptivedataApiService } from 'src/app/service/adaptivedata-api.service';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  UserContactGroup: any;

  constructor(private formbuilder: FormBuilder,
    private modalService: NgbModal,
    private dataService: AdaptivedataApiService,
    public ngxShowLoader: NgxSpinnerService,
    private router: Router,

    private dataservice: AdaptivedataApiService) { }

  ngOnInit(): void {
    this.UserContactGroup = this.formbuilder.group({
      UserContactName: ['', Validators.required],
      UserContactMessage: ['', Validators.required],
      UserContactEmail: ['', Validators.compose([Validators.required, Validators.email])],
      UserContactPhone: ['', Validators.required]

    })
  }

  /**
   * Getter method to allow form to retrieve controls shorthand
   */
  get registerFormControl() {
    return this.UserContactGroup.controls;
  }

  sendRequest() {
    this.ngxShowLoader.show();
    let contact: Contact = {
      email: this.UserContactGroup.controls.UserContactEmail.value,
      message: this.UserContactGroup.controls.UserContactMessage.value,
      name: this.UserContactGroup.controls.UserContactName.value,
      phone: this.UserContactGroup.controls.UserContactPhone.value
    }
    let customer: Customer = {
      contact: contact
    }

    if (this.UserContactGroup.valid) {

      this.dataservice.saveProfileDb(customer).then((result) => {
        this.ngxShowLoader.show();

        if (result != null) {
          const confirmSubmissionModalRef = this.modalService.open(SubmissionStatusModalComponent, { size: 'lg', backdrop: 'static' })

          confirmSubmissionModalRef.componentInstance.enableGotoProfile = false;
          confirmSubmissionModalRef.componentInstance.enableHome = false;
          confirmSubmissionModalRef.componentInstance.modal_body = AppConfig.MODAL_BODY_SUBMISSION_SUCCESS;
          confirmSubmissionModalRef.componentInstance.modal_header_title = AppConfig.MODAL_HEADER_SUBMISSION_TITLE_SUCCESSFUL;
          confirmSubmissionModalRef.result.then((result) => {
            if (result != null) {
              this.dataService.sendEmail(contact.email, contact.name);
              this.router.navigate([AppConfig.ROUTE_URL_HOME]);
            }
          }).catch((error) => {
            console.log(error.message);
            this.ngxShowLoader.hide();

          })
        }

      });
    }
    else {
      this.UserContactGroup.markAllAsTouched();
    }



  }

}
