<div class="container">
<div class="row">
    <div class="col-4">
        <img width="75%" src="../../../assets/img/LOGO-3_resize_200_133.png">
    </div>
    <div class="col-4">

    </div>
    <div class="col-4 align-self-center">
        <span [hidden]="!showSlogan" class="nav_text_right">{{adaptive_slogan3}}</span>
    </div>
</div>
</div>
<br />


<div class="row">
    <div class="col-12" [hidden]="!isWebDevelopment">
        <div class="container">
            <div class="row">
                <div class="col-9">
                    <div class="card">
                        <div class="card-header bg-c-blue order-card">Services >> Web Application Development</div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">Sometimes people think custom website development is a long involve
                                    process and requires a team of salaried professionals to complete. While sometimes
                                    that is true, it's not always the case. <br /><br /> Depending on what you're
                                    organizations needs are and complexity involved, some projects can be completed in
                                    one to three months. That includes, gathering your requirements, proposing a design,
                                    establishing or working with your current hosting provider, implementing, and
                                    deploying your solution. <br /><br />At Adaptiv, we like to frequently check-in with
                                    our customers and provide demos so that they are comfortable with what they are
                                    getting, and we know we are giving you what you expected. That approach usually
                                    works and keeps everyone on the same page. <br /><br /> Contact Us. We can have you
                                    up and running in no time.<br /><br /></div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="col-3">
                    <div class="card">
                        <div class="card-header text-center bg-c-blue order-card">Technology Stack</div>
                        <div class="card-body">
                            <h3>Development</h3>
                            <ul id="list">
                                <li>Microsoft C#.Net</li>
                                <li>Microsoft VB.Net</li>
                                <li>Microsoft WCF</li>
                                <li>Microsoft LINQ-SQL</li>
                                <li>Java</li>
                                <li>Javascript/Typescript</li>
                                <li>Jquery</li>
                                <li>Microsoft Angular 9</li>
                                <li>HTML 5</li>
                                <li>Material UI</li>
                                <li>Bootstrap UI</li>
                                <li>Knockout.js</li>
                            </ul>
                            <h3>Database</h3>
                            <ul id="list2">
                                <li>Microsoft SQL Server</li>
                                <li>Oracle/RAC</li>
                                <li>Firestore</li>
                                <li>Marclogic 9</li>
                            </ul>
                            <h3>APIs</h3>
                            <ul id="list3">
                                <li>Content Server</li>
                                <li>Marklogic</li>
                                <li>Facebook</li>
                                <li>Google</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>




    </div>
    <div class="col-12" [hidden]="!isDocumentManagement">
        <div class="container">
            <div class="row">
                <div class="col-8">
                    <div class="card">
                        <div class="card-header bg-c-blue order-card">Services >> Document Management</div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">Does your organization still store paper documents in filing
                                    cabinets? Or do you find yourself searching the network drive for a version of a
                                    document you were editing a week ago, a year ago? Or, is the email server a system
                                    you use to find 'the latest version' by searching in the 'Sent' folder? <br /><br />If
                                    so, you might want to consider a Document Management System (DMS). Some basic
                                    features of a DMS include:<br /><br />
                                    <ul id="list">
                                        <li>Centralizing your documents so you can go to the same location every time</li>
                                        <li>Full text searching so you can use your favorite search app to find a
                                            document</li>
                                        <li>Online editing so you can use your organization's office editing suite</li>
                                        <li>Versioning, so you can review or revert to a specific revision</li>
                                    </ul>
                                
                                    Sound, appealing? <br /><br />

                                    Adaptiv Information Systems has over 12 years helping federal and commercial clients
                                    implement and manage small and large document management systems, providing organizations a simple and
                                    easy way to organize, store, and secure business assets.<br /><br />
                                    Contact Us and allow us to assess your document management needs.

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="col-4">
                    <div class="card">
                        <div class="card-header text-center bg-c-blue order-card">Document Management Systems</div>
                        <div class="card-body">
                            <h3>Vendors</h3>
                            <ul id="list">
                                <li>Open Text Content Server</li>
                                <li>MarcLogic</li>
                                <li>IBM FileNet</li>
                                <li>Microsoft SharePoint</li>
                                <li>Google Drive</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>




    </div>
    <div class="col-12" [hidden]="!isBpm">
        <div class="container">
            <div class="row">
                <div class="col-8">
                    <div class="card">
                        <div class="card-header bg-c-blue order-card">Services >> Business Process Management (BPM)</div>

                        <div class="card-body">
                            <div class="row">
                                <div class="col-12">Does your organization perform repeatable tasks as a part of an overall process to deliver a product? <br /><br />Does your organization:
                                    
                                    <ul id="list">
                                        <li>Respond to Freedom of Information Act (FOIA) requests</li>
                                        <li>Review submitted documentation for publication</li>
                                        <li>Maintain or track the disposition of records</li>
                                        <li>Perform employee on-boarding</li>
                                        <li>Perform and track invoice approvals</li>
                                    </ul>
                                
                                    Some of these examples are simple process flows and some are not.  Adaptiv's BPM tools can allow your organization's operation to function in a productive, efficient, and manageable process.<br /><br />
                                    
                                    Adaptiv Information Systems has over 12 years helping federal and commercial clients
                                    implement and manage small and large business process management systems, allowing organizations to operate more efficiently and have confidence in their processes.<br /><br />
                                    Contact Us and allow us to assess your business process needs.

                                </div>
                            </div>

                        </div>

                    </div>
                </div>
                <div class="col-4">
                    <div class="card">
                        <div class="card-header text-center bg-c-blue order-card">BPM Systems</div>
                        <div class="card-body">
                            <h3>Vendors</h3>
                            <ul id="list">
                                <li>Open Text</li>
                                <li>IBM FileNet</li>
                                <li>Microsoft SharePoint</li>
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </div>




    </div>

</div>