import { Component, OnInit } from '@angular/core';
import { AppConfig } from 'src/app/model/constants/app-config';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap'
import { FormBuilder } from '@angular/forms';


@Component({
  selector: 'app-submission-status-modal',
  templateUrl: './submission-status-modal.component.html',
  styleUrls: ['./submission-status-modal.component.scss']
})
export class SubmissionStatusModalComponent implements OnInit {
  SubmissionStatusGroup: any;
  modal_body;
  modal_header_title;
  enableContinue = true;
  enableHome = true;
  enableRetrySubmission = true;
  enableGotoProfile = true;

  constructor(public activeModal: NgbActiveModal,
    
              private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.formBuilder.group({
      id: ['']
    })
  }


  goHome()
  {
    this.activeModal.close({status: AppConfig.ROUTE_URL_HOME});
  }
}
