<!-- Navigation -->
<nav class="navbar navbar-expand-lg navbar-custom static-top mb-5 shadow">
    <div class="container">
      <a class="navbar-brand" href="#"></a>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
          </button>
      <div class="collapse navbar-collapse" id="navbarResponsive">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item active">
            <a class="nav-link navbar-brand" href="">{{BUTTON_TOOLBAR_HOME}}
                  <span class="sr-only">(current)</span>
                </a>
          </li>
          <li class="nav-item">
            <a routerLink="/about" class="nav-link navbar-brand" href="">{{BUTTON_TOOLBAR_ABOUT}}</a>
          </li>
          <li class="nav-item">
            <a class="nav-link navbar-brand" href="#">{{BUTTON_TOOLBAR_SERVICES}}</a>
          </li>
          <li class="nav-item">
            <a (click)="openContact($event)"  href="#" class="nav-link navbar-brand" href="#">{{BUTTON_TOOLBAR_CONTACT_US}}</a>
          </li>
         
        </ul>
      </div>
    </div>
  </nav>