export class AppConfig {

    public static readonly USER_ROUTE_ACTION_HOME = "Home";
    public static readonly USER_ROUTE_ACTION_SERVICES = "Services";
    public static readonly USER_ROUTE_ACTION_CONTACTUS = "Contact Us";
    public static readonly USER_ROUTE_ACTION_ABOUT = "About"

    public static readonly MODAL_BODY_SUBMISSION_SUCCESS = "Thank you for your inquiry! Your request has been successfully sent. Someone will be in contact with your shortly to answer any questions you may have.";
    public static readonly MODAL_HEADER_SUBMISSION_TITLE_SUCCESSFUL = "Information Request Success";

    public static readonly ADAPTIVE_DESCRIPTION_1 = "Adaptiv Information Systems is a technology provider specializing in custom software solutions and integrating Enterprise Content Management Systems into both small and large organizations."
    public static readonly ADAPTIVE_DESCRIPTION_2 = "From basic information base website development to a complete process driven web application, Adaptiv Information Systems can be your go-to solutions provider to deliver a productive, efficient, and managable solution."
    public static readonly ADAPTIVE_DESCRIPTION_3 = "We are experts in Content Management, Business Process Management (BPM), and Document Management (DMS).  We can recommend a Commercial Of The Shelf (COTS) product or build and integrate a custom solution for your business needs."
    public static readonly ADAPTIVE_DESCRIPTION_4 = "We have experience in secure environments and all of our developers are cleared professionals"
    public static readonly ADAPTIVE_SLOGAN_1 = '" ...with the right tools, your business can be productive, efficient, and adaptable."';
    public static readonly ADAPTIVE_SLOGAN_2 = "Building and integrating software solutions that work for you"
    public static readonly ADAPTIVE_SLOGAN_3 = '" ... we like to frequently check-in with our customers and provide demos.  That way customers know what they are getting, and we know we are giving you what you expected."';
    
    public static readonly BUTTON_WEB_DEVELOPMENT = "Web Application Development"
    public static readonly BUTTON_DOCUMENT_MANAGEMENT = "Document Management"
    public static readonly BUTTON_BPM = "Business Process Management"

    public static readonly ROUTE_URL_SERVICES = "services"
    public static readonly ROUTE_URL_HOME = "home"
    public static readonly ROUTE_URL_CONTACT = "contact"

    public static readonly QUERY_TYPE_WEBDEV = "WebDev";
    public static readonly QUERY_TYPE_DOCMAN = "DocMan";
    public static readonly QUERY_TYPE_BPM = "Bpm";
    public static readonly QUERY_TYPE = "type";

    
    //FireStore Database section
    public static readonly DATABASE_TABLE_CUSTOMER = "Customer";

    public static readonly EMAILJS_SERVICEID = 'service_llsjx57';
    public static readonly EMAILJS_TEMPLATEID = 'template_f0k9q6z';
    public static readonly EMAILJS_USERID = 'user_rN5OTzdAOR4xSqP72LyNT';

}
