import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppConfig } from 'src/app/model/constants/app-config';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

  
  BUTTON_TOOLBAR_CONTACT_US = AppConfig.USER_ROUTE_ACTION_CONTACTUS;
  BUTTON_TOOLBAR_SERVICES = AppConfig.USER_ROUTE_ACTION_SERVICES;
  BUTTON_TOOLBAR_HOME = AppConfig.USER_ROUTE_ACTION_HOME
  BUTTON_TOOLBAR_ABOUT = AppConfig.USER_ROUTE_ACTION_ABOUT
  

  userProfile: any;
  isAdmin = false;
  isLoggedIn = false;
  username;

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  openContact(action){
    this.router.navigate([AppConfig.ROUTE_URL_CONTACT]);
    action.preventDefault();
  }

}
